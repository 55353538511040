'use strict';


//mudança entre formulário dos dados do usuário e endereço
function toggleFormRegistration(){
  $('.address-formRegistration').toggleClass('d-none');
  $('.tabAccount-formRegistration').toggleClass('d-none');
  $('.stepBar.address').toggleClass('active');
  $('.progress-count.firstStep').toggleClass('icon-check');
  $('.stepBar.registration').toggleClass('step-confirmed')
}

//limpa todos formulários de registro
function resetAllForms(){
  $('form.registration').trigger('reset');
  $('form.address-formRegistration').trigger('reset');
  $('#registration-form-stateRegistration').prop('disabled', false).attr('required', true);
  $('.address-formRegistration input').removeAttr('readonly');
  $('.address-formRegistration select').removeAttr('style')
  $('form.registration input').removeClass('is-invalid');
}

//submit para criação da conta
function submitRegistration(){
  $('form.address-formRegistration').submit(function (e) {
    e.preventDefault();
    var $form = $(this);
    var url = $form.attr('action');
    $form.spinner().start();
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: $form.serialize(),
        success: function (data) {
            $form.spinner().stop();
            if (!data.success) {
                formValidation($form, data);
            } else {
                location.href = data.redirectUrl;
            }
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            }
            $form.spinner().stop();
        }
    });
    return false;
});
}

$(document).ready(function() {
  //CNPJ isento de inscrição estadual
    $('#exemptStateRegistration').on('change', function() {
      if ($(this).is(':checked')) {
        $('#registration-form-stateRegistration').val('').prop('disabled', true).removeAttr('required');
      } else {
        $('#registration-form-stateRegistration').prop('disabled', false).attr('required', true);
      }
    });

    $('.stepBar.registration,.endButtons-registration .js-goback-btn').on('click', function(){
      if($('.stepBar.registration').hasClass('step-confirmed'))
      toggleFormRegistration();
    })

    $('#nav-accountBusiness-tab, #nav-accountPersonal-tab').on('click',function(){
      if(!$(this).hasClass('active')){
        resetAllForms();
        $('.stepBar.registration').trigger('click').removeAttr('readonly');
      }
    })
    

    submitRegistration();

})
module.exports={
  toggleFormRegistration,
}
